export default {
  ua: {
    portNote_2: 'розетка або тайп 2 кабель',
    portNote_10: 'висить біля гаража # 14',
    portNote_39: 'прохання зменшити струм в машині до 25 А',
    portNote_40: 'прохання зменшити струм в машині до 25 А',
    portNote_41: 'прохання зменшити струм в машині до 25 А',
  },
  ru: {
    portNote_2: 'розетка або тайп 2 кабель',
  },
  en: {
    portNote_2: 'euro plug or Type 2 cable',
  },
};
